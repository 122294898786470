<template>
  <div class="wrapper" v-if="storeInitialized && isReady" v-show="contentReady">
    <div class="content-wrapper">
      <div
        class="dashboard-content"
        :style="dashboardContentCSS"
        ref="dashboardContent"
      >
        <DashboardEquipment
          :equipmentId="connectorId"
          :screenId="screenId"
        ></DashboardEquipment>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardEquipment from "@/views/private/DashboardEquipment.vue";
export default {
  name: "ContentView",
  components: {
    DashboardEquipment
  },
  data() {
    return {
      contentReady: false,
      storeInitialized: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"];
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    isRulesReady() {
      return this.$store.getters["user/isRulesReady"];
    },
    isEquipmentReady() {
      return this.$store.getters.isReady;
    },
    isReady() {
      return this.isEquipmentReady && this.contract && this.isRulesReady;
    },
    connectorId() {
      return this?.params?.connector_id || null;
    },
    screenId() {
      return this?.params?.screen_id || null;
    },
    dashboardContentCSS() {
      // return {
      //   "--background-color": "transparent",
      //   "--transform": "scale(0.91)",
      //   "--width": "100%",
      //   "--margin-left": "0",
      //   "--min-height": "auto",
      //   "--height": "auto"
      // };
      try {
        if (this?.params?.wrapper?.content_css_vars) {
          return JSON.parse(this?.params?.wrapper?.content_css_vars);
        }
      } catch (error) {
        console.log(error);
      }
      return {};
    }
  },
  methods: {
    onContentReady() {
      this.contentReady = true;
    },
    onMessageArrived(e) {
      if (e.data.hi_msg == "custom-page:sync" && e.data.value) {
        if (e.data.value.config) {
          // do not just overwrite the entire object,
          // since there are components already initialized and pointing straight to it;
          for (var p in e.data.value.config || {}) {
            this.$root.config[p] = e.data.value.config[p];
          }
        }
        if (e.data.value.root) {
          // console.log("custom-page:sync ok");
          this.$store.commit("INITIALIZE", e.data.value.root);
          Object.keys(e.data.value.modules || {}).forEach((k) => {
            this.$store.commit(`${k}/INITIALIZE`, e.data.value.modules[k]);
          });
        } else {
          // console.log("custom-page:sync nok");
        }
        this.$nextTick(() => {
          if (this.user) {
            this.$store.dispatch("user/configUserRoles");
          }
          this.storeInitialized = true;
        });
      } else if (e.data.hi_msg == "custom-page:destroy") {
        // TODO: it doesn't seems to be any time reached
        window.removeEventListener("message", this.onMessageArrived);
        this.$destroy();
        // remove the element from the DOM
        this.$el.parentNode.removeChild(this.$el);
      }
    }
  },
  beforeCreate() {
    const readParams = () => {
      let params = null;
      // console.log(document.location.search);
      try {
        // TODO all configuration below will be read from _cdim
        if (this.$utils.gup("_cdim")) {
          params = JSON.parse(localStorage.getItem("_cdim"));
        }
      } catch (error) {
        console.log(error);
      }
      if (!params) {
        console.log(
          "TODO: display a message to the user that the configuration is missing"
        );
      }
      return params;
    };
    this.params = readParams();
    let link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "/static/common/css/content-view.css";
    let head = document.getElementsByTagName("head")[0];
    head.appendChild(link);
    // validate screen type
    // app.__vue__.$store.getters["dashboard/screens"].filter(({public})=>public).map(({id})=>id)
    // TODO: move code below to the top page
  },
  created() {
    if (window.self !== window.top) {
      window.addEventListener("message", this.onMessageArrived.bind(this));
      window.top.postMessage({hi_msg: "content-view:ready"}, "*");
    }
    this.$root.$on("dashboard:page_layout", this.onContentReady);
    // debugger;
    if (this.isEquipmentReady) return;
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessageArrived);
    this.$root.$off("dashboard:page_layout", this.onContentReady);
    let head = document.getElementsByTagName("head")[0];
    head.childNodes.forEach((item) => {
      if (item.href && item.href.endsWith("content-view.css"))
        item.parentNode.removeChild(item);
    });
  },
  mounted() {}
};
</script>

<style scoped>
.waiting {
  position: relative;
}
.overlay-loading {
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 80px;
  text-align: center;
  margin-top: 2%;
  color: #607d8b;
  width: 100%;
  padding: 80px;
}
.opaque {
  opacity: 0.5;
}

/* custom content */
#app > .wrapper > .content-wrapper > .dashboard-content {
  background-color: var(--background-color);
  transform: var(--transform);
  width: var(--width);
  margin-left: var(--margin-left);
  min-height: var(--min-height);
  height: var(--height);
}
</style>

<style></style>
